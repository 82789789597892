import React, { useEffect, useState } from "react";
import TextOnPicture from "./TextOnPicture";
import PhotoOnTop from "./PhotoOnTop";
import PhotoOnLeft from "./PhotoOnLeft";

interface IArticleList {
  articles: any[];
}

const ArticleList: React.FC<IArticleList> = ({ articles }) => {

  const isDesktop = window.innerWidth > 996;
  const slices = [articles.slice(0, 3), articles.slice(3)];
  // console.log('ArticleList', data);

  if (!articles || articles.length == 0) {
    return <h2>Nie znaleziono żadnych artykułów</h2>
  }

  return <>
    {slices.map((slice, sliceNo) => {
      return (
        <div className="row">
          {slice.map((item, index) => {
            item.author.show = sliceNo == 0 && index > 0 && index < 3;
            return (
              <div
                className={
                  "col-xs-12 col-md-" + (sliceNo == 0 && index == 0 ? 6 : 3)
                }
              >
                {sliceNo == 0 && index == 0 ? (
                  isDesktop ? (
                    <TextOnPicture article={item} />
                  ) : (
                    <PhotoOnTop article={item} />
                  )
                ) : isDesktop ? (
                  <PhotoOnTop article={item} />
                ) : (
                  <PhotoOnLeft article={item} boxSize="xs" />
                )}
              </div>
            );
          })}
        </div>
      );
    })}
  </>
};

export default ArticleList;
